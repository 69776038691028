<template>
  <div>
    <div class="ac-card c-pointer" @click="onClick">
      <b-row class="m-0">
        <b-col class="p-0">
          <div class="d-flex ac-card__title align-items-start">
            <img
              src="@/assets/images/certificates/badge-success.svg"
              alt="badge-success"
              width="auto"
              height="auto"
              class="ac-card__badge"
            />
            <div class="ml-3 ac-card__label mr-2 mt-1">
              <h5>
                {{ credential.course_title }}
              </h5>
            </div>
          </div>
        </b-col>
        <b-col cols="12" class="p-0">
          <ul class="ac-card__timeline">
            <li>
              <div class="d-flex align-items-center w-100">
                <img
                  src="@/assets/images/certificates/badge-blue.svg"
                  alt="badge"
                  width="34"
                  height="34"
                  class="ml-2"
                />
                <p class="ml-4">
                  {{ $t("achievements.microcredentials") }}
                </p>
                <Icon
                  iconName="chevron-right"
                  style="font-size: 18px"
                  class="c-pointer right-icon"
                />
              </div>
            </li>
          </ul>
        </b-col>
      </b-row>
    </div>
    <b-modal
      :id="`share-download-modal-${index}`"
      size="lg"
      centered
      dialog-class="share-modal"
    >
      <template #modal-header="{ close }">
        <div class="d-flex w-100 align-items-center">
          <b-icon
            icon="arrow-left"
            class="b-icon-size c-pointer"
            @click="close()"
          ></b-icon>
          <div class="flex-grow-1 justify-content-center d-flex">
            <h5 class="mb-0">
              {{
                action === "share"
                  ? $t("achievements.share_credential")
                  : $t("achievements.download_credential")
              }}
            </h5>
          </div>
          <div class="d-flex c-pointer">
            <img
              src="@/assets/images/close.svg"
              alt="close"
              @click="close()"
              width="14"
              height="14"
            />
          </div>
        </div>
      </template>
      <div class="sd-main">
        <b-row>
          <b-col cols="2">
            <div class="d-flex mt-5 h-100 sd-main__img">
              <img
                src="@/assets/images/certificates/badge-blue.svg"
                alt="badge-blue"
                width="68"
                height="68"
              />
            </div>
          </b-col>
          <b-col cols="10" class="lxp-badge__main">
            <div class="badge-main__img">
              <img
                :src="
                  require(`@/assets/images/logo/whoa-logo-white-${logoLanguage}.svg`)
                "
                alt="Logo"
                width="auto"
                height="48"
              />
            </div>
            <h2>
              {{ courseCredential.course_title }}
            </h2>
            <div class="sd-main__des mb-5">
              <label>Descrition of Module</label>
              <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer
                adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                laoreet dolore magna aliquam erat volutpat.
              </p>
            </div>
            <p class="badge-main__name">
              {{ getAuthProfile.name }}
            </p>
            <div class="badge-main__share mb-5">
              <label
                >{{ $t("achievements.credential_url") }}:
                <input
                  type="text"
                  name="credential"
                  id="credential-url"
                  class="invisible"
                  :value="courseCredential.download_url"
                />
                <a>{{ courseCredential.download_url }}</a>
              </label>
            </div>
            <div class="d-flex" v-if="action == 'share'">
              <img
                src="@/assets/images/icons/copy-white.svg"
                alt="copy"
                width="25"
                height="auto"
                class="mr-5"
              />
              <img
                src="@/assets/images/icons/email.svg"
                alt="email"
                width="25"
                height="auto"
                class="mr-5"
              />
              <img
                src="@/assets/images/social-media/linkedin.svg"
                alt="LinkedIn"
                width="auto"
                height="auto"
              />
            </div>
            <div v-if="action == 'download'">
              <div class="w-50 mb-5">
                <Select
                  label=""
                  placeholder="Select Certificate Language"
                  :dropdownArr="languageArr"
                  v-model="certificate_language"
                  @click="onSelect($event)"
                />
              </div>
              <b-button class="ob-btn ob-btn-primary">{{
                $t("general.download_pdf")
              }}</b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import getLogo from "@/core/mixins/getLogo";
import Select from "../components/WrapperComponents/Select";
import Icon from "@/components/WrapperComponents/Icon.vue";
export default {
  mixins: [getLogo],
  props: {
    credential: Object,
    index: Number
  },
  data() {
    return {
      showDd: false,
      courseCredential: "",
      action: "share",
      certificate_language: "",
      languageArr: ["Arabic", "English", "Russian", "Spanish", "Chinese"]
    };
  },
  components: { Select, Icon },
  computed: {
    ...mapGetters(["getAuthProfile"])
  },
  methods: {
    openWin(a) {
      let routeData = this.$router.resolve({
        path: `/certificate/${this.credential.id}`,
        query: { id: a.id }
      });
      window.open(routeData.href, "_blank");
    },
    shareUrl(m) {
      this.courseCredential = m;
      this.action = "share";
      this.$bvModal.show(`share-download-modal-${this.index}`);
    },
    download() {
      this.courseCredential = this.credential.micro_credentials[0];
      this.action = "download";
      this.$bvModal.show(`share-download-modal-${this.index}`);
    },
    onSelect(e) {
      this.certificate_language = e;
    },
    onClick() {
      this.$store.commit("SET_CREDENTIAL", this.credential);
      this.$emit("click");
    }
  }
};
</script>
<style lang="scss">
.ac-card,
.ac-div {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  width: 334px;
  margin: 0 25px 25px 0;
  .ac-card__title {
    padding: 20px 10px 30px;
    .ac-card__label {
      width: calc(100% - 100px);
      label {
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #0043ac;
      }
      h5 {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: rgba(0, 0, 0, 0.87);
        text-align: left;
        align-items: center;
      }
    }
    .ac-card__badge {
      width: 60px;
      height: 60px;
    }
    .ac-card__cta {
      .b-icon {
        color: #0043ac;
      }
    }
  }
  .ac-card__timeline {
    list-style: none;
    margin-bottom: 0;
    padding: 0 20px;
    border-top: 1px solid #acacac;
    li {
      display: flex;
      align-items: center;
      position: relative;
      padding: 15px 5px 12px 5px;
      .right-icon {
        .text-primary {
          color: #0057e0 !important;
        }
      }
      &:last-child {
        div {
          margin-bottom: 0;
          &::after {
            content: none;
          }
        }
      }
      .b-icon {
        &:focus {
          outline: none;
        }
      }
      div {
        cursor: pointer;
        .ac-card__share {
          z-index: 1;
          width: 20px;
        }
        p {
          font-weight: 500;
          font-size: 11px;
          line-height: 16px;
          letter-spacing: 1.25px;
          color: #0043ac;
          margin-bottom: 0;
          text-align: left;
          text-transform: uppercase;
          width: 100%;
        }
      }
    }
  }
}
.ac-card {
  box-shadow: 1px 2px 5px 2px #e5e5e5;
  &:hover {
    background: linear-gradient(
        0deg,
        rgba(0, 87, 224, 0.08),
        rgba(0, 87, 224, 0.08)
      ),
      #fbfdfd;
  }
}
.ac-div {
  width: auto;
  border: none;
  margin: 0;
  padding: 10px 30px 20px;
  .ac-card__title {
    padding: 20px 10px;
    .ac-card__label {
      width: calc(100% - 20px);
      h5 {
        font-size: 16px;
        width: 60%;
        line-height: 24px;
      }
    }
  }
  .ac-card__timeline {
    padding: 15px 10px;
    .ac-mc__title {
      margin-left: 35px;
      label {
        font-weight: 500;
        font-size: 13px;
        line-height: 14px;
        letter-spacing: 0.2px;
        color: #52575c;
        margin-top: 5px;
      }
    }
    .ac-mc__label {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.2px;
      color: #52575c;
      margin-bottom: 0;
    }
    .ac-mc__options {
      position: relative;
      font-size: 16px;
    }
  }
}
.ac-footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 0 30px 20px;
  p {
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    letter-spacing: 0.25px;
    color: #25282b;
    margin-right: 10%;
    margin-bottom: 0;
  }
  .d-flex {
    .b-icon {
      font-size: 14px;
      &:last-child {
        margin-left: 25px;
      }
    }
  }
}
.share-modal {
  .modal-header {
    border-bottom: 2px solid #e8e8e8;
    .b-icon-size {
      font-size: 25px;
    }
  }
  .sd-main {
    .sd-main__img {
      padding-top: 45%;
      justify-content: center;
    }
    .lxp-badge__main {
      h2 {
        color: #52575c;
      }
      .sd-main__des {
        label,
        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: #52575c;
        }
        p {
          font-weight: normal;
        }
      }
      .badge-main__share {
        label {
          width: 100%;
        }
      }
    }
  }
}
// rtl layout
[dir="rtl"] {
  .ac-card,
  .ac-div {
    .ac-card__title {
      .ac-card__label {
        h5 {
          text-align: right;
        }
      }
    }
    .ac-card__timeline {
      li {
        div {
          p {
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
